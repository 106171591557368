import React, {useEffect} from 'react';
import {defineMessages} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {IModuleGridItem} from '@components/data-grid';
import {CurrencyCell} from '@components/data-grid/mui';
import {LayoutDetailsHeaderActions, LayoutDetailsHeaderItem} from '@components/layout';
import {
    AccountVerification,
    EntityFilterType,
    EntityType as EventEntityType,
    SecurityCase,
    Transaction,
    TransactionStatus,
    UserAccountStatus,
} from '@models/generated/graphql';
import {ModuleName} from '@models/modules';
import {EntityType, PageIdDescriptor, TransactionFilterKeys, TransactionQueryFields} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit, ViewType} from '@redux/view';
import {formatTimestamp, isRecent, isStringNullOrEmpty} from '@utils';

import {Filter} from 'src/common/types';
import {PageViewers, usePageViewPing, usePageViews} from 'src/features/block-page-view';
import {ActionHistoryModalAction} from '../../action-history/components/ActionHistoryModalAction';
import {ActionType} from '../../action-history/types';
import {setBreadcrumbAction} from '../../app/breadcrumb/actions';
import {useJurisdictionConfig} from '../../app/config/hooks';
import {emptyCellCharacter} from '../../module-shared/utils';

import {WorkspaceHeader} from './WorkspaceHeader';

type WithdrawalDetailsHeaderProps = {
    transactionId: string;
};

class WithdrawalGridItem extends Transaction implements IModuleGridItem {
    id: string;
    account_status?: UserAccountStatus;
    latest_security_kyc?: AccountVerification;
    latest_payment_kyc?: AccountVerification;
    security_cases?: SecurityCase[];
    country?: string;

    serverId: string;

    get isNew() {
        return this.status_log && this.status_log.length ? isRecent(this.status_log[0].logged_at, 1) : false;
    }

    constructor(transactionId?: string, status?: TransactionStatus, amount?: number) {
        super();
        this.id = transactionId;
        this.serverId = transactionId;
        this.transaction_id = transactionId;
        this.transaction_status = status;
        this.amount = amount;
    }
}

const localizedWithdrawalDetailsHeader = defineMessages({
    withdrawalStatus: {
        id: 'WD_withdrawalStatus',
        defaultMessage: 'Withdrawal Status',
    },
    withdrawalAmount: {
        id: 'WD_withdrawalAmount',
        defaultMessage: 'Withdrawal Amount',
    },
    withdrawalDate: {
        id: 'WD_withdrawalDate',
        defaultMessage: 'Date',
    },
    withdrawalProcessingRoute: {
        id: 'WD_withdrawalProcessingRoute',
        defaultMessage: 'Withdrawal Workspace',
    },
});

function useWithdrawalDetailsHeaderData(transactionId: string, viewType: ViewType): WithdrawalGridItem {
    const fields: TransactionQueryFields[] = ['transaction_id', 'transaction_status', 'amount', 'transaction_started_ts'];
    const filters: Filter<string, TransactionFilterKeys>[] = [{key: 'transactionId', value: transactionId}];

    const {items} = useViewInit<WithdrawalGridItem, TransactionFilterKeys, TransactionQueryFields>({
        viewType,
        displayName: localizedWithdrawalDetailsHeader.withdrawalProcessingRoute,
        entity: {
            entity: EntityType.Transaction,
            fields,
        },
        realtime: {
            entity: EntityType.Transaction,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [{type: RealtimeMessageTrigger.Update}],
        },
        defaultFilters: filters,
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'transactionId')?.value),
    });
    const withdrawalItem = items[0];
    return withdrawalItem;
}

export const WithdrawalDetailsHeader = ({transactionId}: WithdrawalDetailsHeaderProps) => {
    const dispatch = useDispatch();
    const {currency} = useJurisdictionConfig();
    const viewType = 'WithdrawalDetailsHeader';
    const withdrawalItem = useWithdrawalDetailsHeaderData(transactionId, viewType);
    const pageIdDescriptor: PageIdDescriptor = transactionId ? {recordId: transactionId, pageType: 'withdrawal-details'} : null;
    const pageViewers = usePageViews({pageIdDescriptors: pageIdDescriptor ? [pageIdDescriptor] : [], viewType});

    usePageViewPing({
        pageIdDescriptor: {recordId: transactionId, pageType: 'withdrawal-details'},
        viewType,
    });

    useEffect(() => {
        const withdrawalProcessingUrl = `/${ModuleName.WithdrawalProcessing}`;
        const withdrawalDetailsUrl = `/${ModuleName.WithdrawalProcessing}/${transactionId}`;
        dispatch(
            setBreadcrumbAction([
                {url: withdrawalProcessingUrl, title: localizedWithdrawalDetailsHeader.withdrawalProcessingRoute, clickable: true},
                {url: withdrawalDetailsUrl, title: transactionId, clickable: false},
            ])
        );
    }, [transactionId]);

    return (
        <WorkspaceHeader>
            <LayoutDetailsHeaderItem>
                <PageViewers
                    users={pageViewers.all
                        ?.filter(u => u?.email)
                        .map(u => ({
                            id: u.id,
                            lastOpenedAtSeconds: u.lastOpenedAt?.seconds,
                            email: u.email,
                            name: `${u.firstName} ${u.lastName}`,
                            username: u.email,
                        }))}
                />
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderItem label={localizedWithdrawalDetailsHeader.withdrawalStatus}>
                <MemoizedChip chipName="TransactionStatusMark" value={withdrawalItem?.transaction_status} />
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderItem label={localizedWithdrawalDetailsHeader.withdrawalAmount}>
                <CurrencyCell value={withdrawalItem?.amount} currency={currency} />
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderItem label={localizedWithdrawalDetailsHeader.withdrawalDate}>
                <Box>
                    {withdrawalItem?.transaction_started_ts
                        ? formatTimestamp(withdrawalItem?.transaction_started_ts, 'date-time')
                        : emptyCellCharacter}
                </Box>
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderActions>
                <ActionHistoryModalAction
                    uid={transactionId}
                    options={{
                        id: transactionId,
                        type: EventEntityType.Transaction,
                        actions: [ActionType.UpdatedTransactionStatus],
                        filterType: EntityFilterType.Specific,
                    }}
                />
            </LayoutDetailsHeaderActions>
        </WorkspaceHeader>
    );
};
