import {createMap, forMember, mapFrom, Mapper} from '@automapper/core';

import {IMapping} from '@auto-mapper';
import {PageView} from '@models/generated/graphql';
import {PageIdDescriptorConverter} from '@services/pageViewService';

import {PageViewViewModel} from './types';

export class PageViewMapping implements IMapping {
    createMapping(mapper: Mapper): void {
        createMap(
            mapper,
            PageView,
            PageViewViewModel,
            forMember(
                vm => vm.userId,
                mapFrom(m => m.user_id)
            ),
            forMember(
                vm => vm.pageId,
                mapFrom(m => m.page_id)
            ),
            forMember(
                vm => vm.lastOpenedAt,
                mapFrom(m => m.last_opened_at)
            ),
            forMember(
                vm => vm.recordId,
                mapFrom(m => new PageIdDescriptorConverter().parse(m.page_id)?.recordId)
            ),
            forMember(
                vm => vm.pageType,
                mapFrom(m => new PageIdDescriptorConverter().parse(m.page_id)?.pageType)
            )
        );
    }
}
