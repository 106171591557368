import React from 'react';
import {defineMessages, FormattedNumber, MessageDescriptor} from 'react-intl';
import {Box, Divider, Typography} from '@mui/material';
import {makeStyles} from 'tss-react/mui';

import {ErrorAlert, SuccessAlert} from '@components/alerts/InfoAlert';
import {MemoizedDefaultChip} from '@components/chip/ChipRenderer';
import {ChipType, ChipVariant} from '@components/chip/types';
import LocalizedText from '@components/i18n/LocalizedText';
import {Icon, IconColor} from '@components/icons/Icon';
import {CustomIcon} from '@components/icons/types';
import {TransactionType} from '@models/generated/graphql';
import {TransactionViewModel} from '@models/transaction';
import {CustomTheme} from '@style';

import {useJurisdictionConfig} from '../../app/config/hooks';
import {ValidationResult} from '../../block-bulk-actions';
import {getValidationErrorItems} from '../hooks';

import {DataGridBulkTransactionColumns, DataGridBulkTransactionsClient} from './DataGridBulkTransaction';

export const localizedBulkStepValidation = defineMessages({
    uidNotExists: {
        id: 'BulkTransactionValidationStep_uidNotExists',
        defaultMessage: "UID doesn't exist",
    },
    balanceIsNotEnough: {
        id: 'BulkTransactionValidationStep_balanceIsNotEnough',
        defaultMessage: 'Not enough funds',
    },
    amountNotInteger: {
        id: 'BulkTransactionValidationStep_amountNotInteger',
        defaultMessage: 'Please use number without separators, coma and period',
    },
    amountNotNumber: {
        id: 'BulkTransactionValidationStep_amountNotNumber',
        defaultMessage: 'Please use integer or decimal number with point separator',
    },
    amountNotPositive: {
        id: 'BulkTransactionValidationStep_amountNotPositive',
        defaultMessage: 'Please put amount > 0',
    },
    amountDecimalPartLengthIsNotValid: {
        id: 'BulkTransactionValidationStep_amountDecimalPartLengthIsNotValid',
        defaultMessage: 'Please put amount with decimal part length <= 2',
    },
    typeIsNotValid: {
        id: 'BulkTransactionValidationStep_typeIsNotValid',
        defaultMessage: 'Invalid input',
    },
    reasonCodeIsNotValid: {
        id: 'BulkTransactionValidationStep_reasonCodeIsNotValid',
        defaultMessage: 'Invalid reason codes',
    },
    lineIsTooLarge: {
        id: 'BulkTransactionValidationStep_lineIsTooLarge',
        defaultMessage: 'Currently batch only supports less than 200 transaction lines. Please reduce the transaction line',
    },
    transactionsValidated: {
        id: 'BulkTransactionValidationStep_transactionsValidated',
        defaultMessage: 'Transactions successfully validated',
    },
    transactionsNotValidated: {
        id: 'BulkTransactionValidationStep_transactionsNotValidated',
        defaultMessage: 'Transactions not validated',
    },
    errors: {
        id: 'BulkTransactionValidationStep_errors',
        defaultMessage: 'Errors',
    },
    totalCredit: {
        id: 'BulkTransactionValidationStep_totalCredit',
        defaultMessage: 'Total amount of credit',
    },
    totalDebit: {
        id: 'BulkTransactionValidationStep_totalDebit',
        defaultMessage: 'Total amount of debit',
    },
});

const useBulkStepValidationContentStyles = makeStyles()((theme: CustomTheme) => ({
    bulkStepValidationContentResultContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
    },
    bulkStepValidationContentResultTitle: {
        display: 'flex',
        alignItems: 'center',
        columnGap: theme.spacing(1.5),
    },
    bulkStepValidationContentResultSubtitle: {
        marginTop: theme.spacing(1),
    },
    bulkStepValidationContentDivider: {
        marginTop: theme.spacing(2),
    },
    bulkStepValidationContentResultIcon: {
        fontSize: '1.5em',
    },
    bulkStepValidationContentResultChip: {
        display: 'flex',
        columnGap: theme.spacing(0.75),
        rowGap: theme.spacing(1.25),
        flexWrap: 'wrap',
    },
    bulkStepValidationContentFinanceSummary: {
        display: 'flex',
        justifyContent: 'space-between',
        margin: theme.spacing(2.5, 0),
    },
    bulkStepValidationContentGrid: {
        marginTop: theme.spacing(2.5),
        padding: 0,
        height: '380px',
        display: 'flex',
        flexDirection: 'column',
    },
}));

type BulkStepValidationContentSuccessfulProps = {
    validTransactions: ValidationResult<TransactionViewModel>[];
    alertTitle: MessageDescriptor;
};

export function BulkStepValidationContentSuccessful({validTransactions, alertTitle}: BulkStepValidationContentSuccessfulProps) {
    const {classes} = useBulkStepValidationContentStyles();

    return (
        <>
            <SuccessAlert title={alertTitle} />
            <Box className={classes.bulkStepValidationContentResultContainer}>
                <Box className={classes.bulkStepValidationContentResultTitle}>
                    <Typography variant="body1">
                        <LocalizedText label={localizedBulkStepValidation.transactionsValidated} />
                    </Typography>
                </Box>
                <Box className={classes.bulkStepValidationContentResultChip}>
                    <MemoizedDefaultChip variant={ChipVariant.GreyInverted} type={ChipType.Status} value={`${validTransactions.length}`} />
                </Box>
            </Box>
            <Divider className={classes.bulkStepValidationContentDivider} />
            <ValidationFinancialSummary transactions={validTransactions.map(r => r.item)} />
        </>
    );
}

type BulkStepValidationContentFailedProps = {
    validTransactions: ValidationResult<TransactionViewModel>[];
    invalidTransactions: ValidationResult<TransactionViewModel>[];
    gridColumns: DataGridBulkTransactionColumns[];
    alertTitle: MessageDescriptor;
};

export function BulkStepValidationContentFailed({
    validTransactions,
    invalidTransactions,
    gridColumns,
    alertTitle,
}: BulkStepValidationContentFailedProps) {
    const {classes} = useBulkStepValidationContentStyles();
    const errorItems = getValidationErrorItems(invalidTransactions);
    const maxRows = 100;

    return (
        <>
            <ErrorAlert title={alertTitle} />
            <Box className={classes.bulkStepValidationContentResultContainer}>
                <Box className={classes.bulkStepValidationContentResultTitle}>
                    <Typography variant="body1">
                        <LocalizedText label={localizedBulkStepValidation.transactionsValidated} />
                    </Typography>
                </Box>
                <Box className={classes.bulkStepValidationContentResultChip}>
                    <MemoizedDefaultChip variant={ChipVariant.GreyInverted} type={ChipType.Status} value={`${validTransactions.length}`} />
                </Box>
            </Box>
            <Divider className={classes.bulkStepValidationContentDivider} />
            <Box className={classes.bulkStepValidationContentResultContainer}>
                <Box className={classes.bulkStepValidationContentResultTitle}>
                    <Icon color={IconColor.Bronze} icon={CustomIcon.ErrorCircle} className={classes.bulkStepValidationContentResultIcon} />
                    <Typography variant="body1">
                        <LocalizedText label={localizedBulkStepValidation.errors} />
                    </Typography>
                </Box>
                <Box className={classes.bulkStepValidationContentResultChip}>
                    <MemoizedDefaultChip
                        variant={ChipVariant.BronzeInverted}
                        type={ChipType.Status}
                        value={`${invalidTransactions.length}`}
                    />
                </Box>
            </Box>
            <Typography variant="body1" color="secondary" className={classes.bulkStepValidationContentResultSubtitle}>
                <LocalizedText label={localizedBulkStepValidation.transactionsNotValidated} />
            </Typography>
            <ValidationFinancialSummary
                transactions={[...validTransactions, ...invalidTransactions].map((r: ValidationResult<TransactionViewModel>) => r.item)}
            />
            <DataGridBulkTransactionsClient
                columns={gridColumns}
                rows={errorItems}
                rowsPerPageOptions={[maxRows]}
                hidePagination={errorItems.length < maxRows}
                className={classes.bulkStepValidationContentGrid}
                isFlexible
            />
        </>
    );
}

type ValidationFinancialSummaryProps = {
    transactions: TransactionViewModel[];
};

function ValidationFinancialSummary({transactions}: ValidationFinancialSummaryProps) {
    const {classes} = useBulkStepValidationContentStyles();
    const {currency} = useJurisdictionConfig();

    function getTotalAmount(transactions: TransactionViewModel[], types: TransactionType[]) {
        return transactions
            .filter(t => types.includes(t.type) || types.includes(t.transaction_type))
            .reduce((sum: number, item: TransactionViewModel) => sum + Number(item.amount), 0);
    }

    const totalCreditAmount = getTotalAmount(transactions, [TransactionType.Credit, TransactionType.P2PTransferCredit]);
    const totalDebitAmount = getTotalAmount(transactions, [TransactionType.Debit, TransactionType.P2PTransferDebit]);

    return (
        <div>
            <div className={classes.bulkStepValidationContentFinanceSummary}>
                <Typography variant="body1">
                    <LocalizedText label={localizedBulkStepValidation.totalCredit} />
                </Typography>
                <Typography variant="body1">
                    <FormattedNumber value={totalCreditAmount ?? 0} style="currency" currency={currency} maximumFractionDigits={0} />
                </Typography>
            </div>
            <div className={classes.bulkStepValidationContentFinanceSummary}>
                <Typography variant="body1">
                    <LocalizedText label={localizedBulkStepValidation.totalDebit} />
                </Typography>
                <Typography variant="body1">
                    <FormattedNumber value={totalDebitAmount ?? 0} style="currency" currency={currency} maximumFractionDigits={0} />
                </Typography>
            </div>
        </div>
    );
}
