import React, {useEffect} from 'react';
import {defineMessages, MessageDescriptor} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Box} from '@mui/material';

import {MemoizedChip} from '@components/chip/ChipRenderer';
import {LayoutDetailsHeaderActions} from '@components/layout/LayoutDetailsHeaderActions';
import {LayoutDetailsHeaderItem} from '@components/layout/LayoutDetailsHeaderItem';
import {AccountVerificationStatus, EntityFilterType, EntityType as EventEntityType} from '@models/generated/graphql';
import {AccountVerificationFilterKeys, AccountVerificationQueryFields, EntityType} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {useViewInit} from '@redux/view';
import {formatTimestamp, isStringNullOrEmpty} from '@utils';

import {ActionHistoryModalAction} from 'src/features/action-history/components/ActionHistoryModalAction';
import {ActionType} from 'src/features/action-history/types';
import {setBreadcrumbAction} from 'src/features/app/breadcrumb/actions';
import {WorkspaceHeader} from 'src/features/block-workspace-details/components/WorkspaceHeader';
import {emptyCellCharacter} from 'src/features/module-shared/utils';
import {AccountVerificationGridItem} from 'src/pages/kyc-processing/types';

const localized = defineMessages({
    kycStatus: {
        id: 'KD_kycStatus',
        defaultMessage: 'KYC Status',
    },
    requestDate: {
        id: 'KD_requestDate',
        defaultMessage: 'Request Date',
    },
});

type KYCDetailsHeaderProps = {
    itemId: string;
    queueUrl: string;
    queueTitle: MessageDescriptor;
};

function useKYCDetailsHeaderData(kycId: string): AccountVerificationGridItem {
    //TODO: use AccountVerification hook to read data
    const viewType = 'KYCDetailsHeader';
    const fields: AccountVerificationQueryFields[] = ['id', 'uid', 'account_verification_status', 'initiated_at.seconds'];

    const {items} = useViewInit<AccountVerificationGridItem, AccountVerificationFilterKeys, AccountVerificationQueryFields>({
        viewType,
        displayName: null,
        entity: {
            entity: EntityType.AccountVerification,
            fields,
        },
        realtime: {
            entity: EntityType.AccountVerification,
            mode: RealtimeUpdatesMode.Silent,
            triggers: [{type: RealtimeMessageTrigger.Update}],
        },
        defaultFilters: [{key: 'id', value: kycId}],
        defaultPaging: {page: 1, pageSize: 1},
        validateFilter: filter => !isStringNullOrEmpty(filter?.find(f => f.key === 'id')?.value),
    });
    const kycItem = items[0];
    return kycItem;
}

export const KYCDetailsHeader = ({itemId, queueUrl, queueTitle}: KYCDetailsHeaderProps) => {
    const dispatch = useDispatch();
    const kycItem = useKYCDetailsHeaderData(itemId);

    useEffect(() => {
        const kycDetailsUrl = `${queueUrl}/${itemId}`;
        dispatch(
            setBreadcrumbAction([
                {url: queueUrl, title: queueTitle, clickable: true},
                {url: kycDetailsUrl, title: itemId, clickable: false},
            ])
        );
    }, [itemId]);

    return (
        <WorkspaceHeader>
            <LayoutDetailsHeaderItem label={localized.kycStatus}>
                <MemoizedChip chipName={nameof<AccountVerificationStatus>()} value={kycItem?.account_verification_status} />
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderItem label={localized.requestDate}>
                <Box>{kycItem?.initiated_at ? formatTimestamp(kycItem?.initiated_at, 'date-time') : emptyCellCharacter}</Box>
            </LayoutDetailsHeaderItem>
            <LayoutDetailsHeaderActions>
                <ActionHistoryModalAction
                    uid={itemId}
                    options={{
                        id: itemId,
                        type: EventEntityType.KycCase,
                        filterType: EntityFilterType.Specific,
                        actions: [
                            ActionType.InitiatedPaymentKYC,
                            ActionType.InitiatedSecurityKYC,
                            ActionType.UpdatedKYCCaseStatus,
                            ActionType.AdminModifiedUsersKYCStatus,
                        ],
                    }}
                />
            </LayoutDetailsHeaderActions>
        </WorkspaceHeader>
    );
};
