import React, {Fragment} from 'react';
import {createPortal} from 'react-dom';
import {MessageDescriptor} from 'react-intl';

import {useDataGridSelectedIds} from '@components/data-grid/mui';
import {LayoutQueue} from '@components/layout';
import Toolbar, {ToolbarItems, ToolbarItemsContainer} from '@components/toolbar/Toolbar';
import {AccountVerificationViewModelKeys} from '@models/account-verification';
import {AccountVerificationStatus, AccountVerificationType} from '@models/generated/graphql';
import {UserProfileViewModelKeys} from '@models/user-profile';
import {AccountVerificationFilterKeys} from '@redux/entity';
import {RealtimeMessageTrigger, RealtimeUpdatesMode} from '@redux/realtime';
import {extendedViewCleanDelay, ViewType} from '@redux/view';

import {Filter} from '../../../common/types';
import {kycWorkspaceHeaderActionContainerId} from '../../../pages/kyc-processing/types';
import {BulkActionKey} from '../../block-bulk-actions';
import {KYCWorkspaceBulkPlayerButton} from '../../block-workspace-queue-kyc';
import {useAccountVerifications, useKYCSettings} from '../hooks';

import {AccountVerificationsFilters, AccountVerificationsFilterType} from './AccountVerificationsFilters';
import {
    DataGridAccountVerificationWithUserProfileServerKYC,
    DataGridAccountVerificationWithUserProfileServerNDRP,
} from './DataGridAccountVerificationWithUserProfile';

type KYCQueueProps = {
    statuses?: AccountVerificationStatus[];
    kycType?: 'KYC' | 'NDRP';
    fields: AccountVerificationViewModelKeys[];
    playerFields?: UserProfileViewModelKeys[];
    viewType: ViewType;
    title?: MessageDescriptor;
    syncWithUrl?: boolean;
    actions?: BulkActionKey[];
    filters?: AccountVerificationsFilterType[];
    triggers?: {type: RealtimeMessageTrigger}[];
    realtimeMode?: RealtimeUpdatesMode;
};

export function KYCQueue({
    statuses,
    kycType = 'KYC',
    fields,
    playerFields,
    viewType,
    title,
    syncWithUrl,
    actions,
    filters,
    triggers,
    realtimeMode,
}: KYCQueueProps) {
    const defaultFilters: Filter<any, AccountVerificationFilterKeys>[] = [];
    if (statuses?.length) {
        defaultFilters.push(
            {
                key: 'accountVerificationStatus',
                value: statuses,
            },
            {
                key: 'defaultAccountVerificationStatus',
                value: statuses,
            }
        );
    }
    if (kycType === 'NDRP') {
        defaultFilters.push({
            key: 'type',
            value: AccountVerificationType.NdrpVerification,
        });
    }

    const userKeys: UserProfileViewModelKeys[] = playerFields ? [...playerFields, 'uid'] : [];
    const {items, totalCount, searchFilter, filterString, handlePageChange, handlePageSizeChange, handleSortChange, handleFilterChange} =
        useAccountVerifications(
            {
                viewType,
                displayName: title,
                fields,
                defaultFilters: getDefaultFilters(),
                syncWithUrl,
                cleanDelay: extendedViewCleanDelay,
                validateFilter: () => true,
                triggers,
                realtimeMode,
            },
            {viewType, displayName: title, fields: userKeys, cleanDelay: extendedViewCleanDelay, validateFilter: () => true}
        );

    const {selectedIds, handleSelect} = useDataGridSelectedIds();

    const {visibleColumns, onVisibleColumnsChange} = useKYCSettings({settingId: viewType, defaultColumns: fields});

    const bulkButtonContainer = document.getElementById(kycWorkspaceHeaderActionContainerId);
    const pinnedColumns: (typeof fields)[number][] = ['id', 'id_ndrp', 'uid', 'email'];
    const primaryFilter = filters.find(f => f === 'text');
    const secondaryFilter = filters.find(f => f === 'date');
    const advancedFilters = filters.filter(f => f !== primaryFilter && f !== secondaryFilter);

    function getDefaultFilters() {
        const defaultFilters: Filter<any, AccountVerificationFilterKeys>[] = [];
        if (statuses?.length) {
            defaultFilters.push(
                {
                    key: 'accountVerificationStatus',
                    value: statuses,
                },
                {
                    key: 'defaultAccountVerificationStatus',
                    value: statuses,
                }
            );
        }
        if (kycType === 'NDRP') {
            defaultFilters.push({
                key: 'type',
                value: AccountVerificationType.NdrpVerification,
            });
        } else {
            defaultFilters.push({
                key: 'type',
                value: [AccountVerificationType.Payment, AccountVerificationType.Security],
            });
        }
        return defaultFilters;
    }

    const DataGrid =
        kycType === 'NDRP' ? DataGridAccountVerificationWithUserProfileServerNDRP : DataGridAccountVerificationWithUserProfileServerKYC;
    return (
        <Fragment>
            <LayoutQueue
                toolbar={
                    <Toolbar>
                        <ToolbarItemsContainer primary>
                            <ToolbarItems streched>
                                {primaryFilter ? (
                                    <AccountVerificationsFilters
                                        filter={filterString}
                                        onFilterChange={handleFilterChange}
                                        filters={['text']}
                                    />
                                ) : null}
                            </ToolbarItems>
                            <ToolbarItems secondary>
                                {secondaryFilter ? (
                                    <AccountVerificationsFilters
                                        filter={filterString}
                                        onFilterChange={handleFilterChange}
                                        filters={['date']}
                                    />
                                ) : null}
                            </ToolbarItems>
                        </ToolbarItemsContainer>
                        {advancedFilters?.length ? (
                            <ToolbarItemsContainer>
                                <ToolbarItems>
                                    <AccountVerificationsFilters
                                        filter={filterString}
                                        onFilterChange={handleFilterChange}
                                        filters={advancedFilters}
                                    />
                                </ToolbarItems>
                            </ToolbarItemsContainer>
                        ) : null}
                    </Toolbar>
                }
                body={
                    <DataGrid
                        hasColumnSelection
                        columns={fields}
                        pinnedColumns={pinnedColumns}
                        rows={items}
                        rowCount={totalCount}
                        onPageChange={handlePageChange}
                        onPageSizeChange={handlePageSizeChange}
                        onSortModelChange={handleSortChange}
                        page={searchFilter?.paging?.page}
                        pageSize={searchFilter?.paging?.pageSize}
                        sortModel={searchFilter?.sorting}
                        checkboxSelection
                        selectedIds={selectedIds}
                        onSelect={handleSelect}
                        visibleColumns={visibleColumns}
                        onColumnsVisibilityUpdate={onVisibleColumnsChange}
                    />
                }
            />
            {bulkButtonContainer && actions?.length
                ? createPortal(<KYCWorkspaceBulkPlayerButton selectedIds={selectedIds} actionKeys={actions} />, bulkButtonContainer)
                : null}
        </Fragment>
    );
}
