import React, {useEffect, useState} from 'react';
import moment from 'moment';
import {makeStyles} from 'tss-react/mui';

import {CircleBox, UserInformation} from '@components/circle-box';
import StyledTooltip from '@components/Tooltip';
import {CustomTheme, SystemColorsEnum} from '@style';

import {activeUsersIntervalInSeconds, inactiveUsersIntervalInSeconds} from '../types';

type UserInfo = {
    id: string;
    name: string;
    email: string;
    username: string;
    lastOpenedAtSeconds: number;
};

type PageViewersProps = {
    users: UserInfo[];
};

const useStyles = makeStyles()((theme: CustomTheme) => ({
    pageViewers: {
        display: 'flex',
        flexDirection: 'row',

        '& > *:first-child': {
            marginLeft: 0,
        },

        '& > *': {
            marginLeft: `-${theme.spacing(1)}`,
            zIndex: 1,
        },
    },
}));

export function PageViewers({users}: PageViewersProps) {
    const {classes} = useStyles();

    const [now, setNow] = useState(moment());
    const active = users
        .filter(u => now.diff(moment.unix(u.lastOpenedAtSeconds), 'seconds') <= activeUsersIntervalInSeconds)
        .map(u => u.id);
    const inactive = users
        .filter(u => now.diff(moment.unix(u.lastOpenedAtSeconds), 'seconds') > inactiveUsersIntervalInSeconds)
        .map(u => u.id);

    useEffect(() => {
        const interval = setInterval(() => {
            setNow(moment());
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    const allUsers = users
        ?.filter(u => u.email)
        .filter(u => !inactive.includes(u.id))
        .sort((a, b) => a.email.localeCompare(b.email));
    const maxVisibleUsers = 5;
    const visibleUsers = allUsers?.slice(0, allUsers?.length > maxVisibleUsers ? maxVisibleUsers : undefined);
    const hiddenUsers = allUsers?.length > maxVisibleUsers ? allUsers.slice(maxVisibleUsers) : [];

    function renderUser(user: UserInfo) {
        return (
            <UserInformation
                {...user}
                active={active.includes(user.id)}
                key={user?.id}
                tooltip={true}
                onlyCircle={true}
                size="medium"
                letterFrom="name"
            ></UserInformation>
        );
    }

    return (
        <div className={classes.pageViewers}>
            {visibleUsers.map(user => renderUser(user))}
            {hiddenUsers?.length ? (
                <div>
                    <StyledTooltip
                        element={
                            <div>
                                <CircleBox size="medium" letter={`+${hiddenUsers.length}`} color={SystemColorsEnum.SystemGrey}></CircleBox>
                            </div>
                        }
                        title={<div className={classes.pageViewers}>{hiddenUsers.map(user => renderUser(user))}</div>}
                        arrow={true}
                    />
                </div>
            ) : null}
        </div>
    );
}
